import { Scrollbar, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useEffect, useRef, useState } from "react";
import { catalogo } from '../utils/constanst';
const Catalogo = () => {
    const [getData, setData] = useState('');
    const [getID, setID] = useState('');
    const [getActive, setActive] = useState(false);
    const ref = useRef<any>();
    const portada = useRef<any>();

    let getElement = document.getElementById(getID);
    let all = document.querySelectorAll(`.swiper-slide img`);

    const onClickHandler = (e: any) => {
        setData(e.target.src);
        setID(e.target.id);
        all.forEach(item => item.classList.remove('active'))
    }

    const onActiveHandler = () => setActive(!getActive);

    useEffect(() => {
        let images = document.querySelectorAll('.portada');
        getElement?.classList.add('active');
        let imgID = getID.split('.');
        images.forEach(item => {
            if (item.id === imgID[0]) {
                item.setAttribute('src', getData);
            }
        });
    }, [getID, getData])

    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={25}
                pagination={{ clickable: true }}
                modules={[Pagination]}
            >
                {catalogo.map((item, index) => (
                    <SwiperSlide key={index + 1}>
                        <div className="catalogo">
                            <div className="catalogo_imagenes">
                                <div className="mobile">
                                    <p className="subtitulo">{item.subtitulo}</p>
                                    <h2 className="titulo">{item.titulo}</h2>
                                </div>
                                <div className="imagenes">
                                    <picture>
                                        <img loading="lazy" id={`${index + 1}`} ref={portada} className='portada' src={item.default} alt="Roble oeste" />
                                    </picture>
                                    <Swiper
                                        spaceBetween={25}
                                        slidesPerView={2}
                                        scrollbar={{ hide: false }}
                                        breakpoints={{
                                            525: {
                                                slidesPerView: 3
                                            },
                                            768: {
                                                slidesPerView: 4
                                            },
                                            1024: {
                                                slidesPerView: 3,
                                            }
                                        }}
                                        modules={[Scrollbar]}
                                        className="galeria"
                                        ref={ref}
                                    >
                                        {item.items.map((img, indexImg) => (
                                            <SwiperSlide id={`${indexImg + 1}`} key={`${indexImg + 1}`}>
                                                <img loading="lazy" id={`${index + 1}.${indexImg + 1}`} onClick={onClickHandler} src={img} alt={`catalogo ${indexImg + 1}`} />
                                            </SwiperSlide>
                                        ))}

                                    </Swiper>
                                </div>
                            </div>
                            <div className="catalogo_info">
                                <div className="hidden">
                                    <div>
                                        <p className="subtitulo">{item.subtitulo}</p>
                                        <h2 className="titulo">{item.titulo}</h2>
                                    </div>
                                    <p className="precio">{item.precio}</p>
                                </div>
                                <p className="descripcion ">{item.descripcion}</p>
                                <div className={`detalle ${getActive ? '' : 'active'}`}>
                                    {item.detalle.map((detalle, detalleIndex) => (
                                        <>
                                            <div key={detalleIndex + 1} className="detalle_container">
                                                <p>{detalle.titulo}</p>
                                                {detalle.elementos.map((elemento, elementoIndex) => (
                                                    <>
                                                        <ul key={elementoIndex + 1}>
                                                            <li>{elemento}</li>
                                                        </ul>
                                                    </>
                                                ))}
                                            </div>
                                        </>
                                    ))}
                                </div>
                                <button className={`boton ${getActive ? 'active' : ''}`} onClick={onActiveHandler}></button>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
}

export default Catalogo;
