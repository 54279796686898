import img from '../assets/img/catalogo';

const links = [
    {link:'mision', title: 'Misión'},
    {link:'vision', title: 'Visión'},
    {link:'servicios', title: 'Servicios'},
    {link:'contactanos', title: 'Contáctanos'},
];

export const catalogo = [
    {
        subtitulo: '¡Vivienda completamente nueva!',
        titulo: 'Roble Oste',
        precio: 'Precio: $ 350,000.00',
        default: img.roble_oeste[0],
        descripcion: 'Vivienda Nueva ubicada en Res. Roble Oeste, Vi Etapa, muy cerca de la entrada del anillo periférico, 424.18 varas de terreno y 447.85 metros de construcción, finos acabados, la vivienda cuenta con:',
        items: img.roble_oeste,
        detalle: [
            {
                titulo: 'Primer Nivel:',
                elementos: ['Garaje para 4 vehículos', 'Sala', 'Dormitorio uno con Baño Completo​', 'Comedor - Cocina amueblada​', 'Baño de Visitas​', 'Terraza amplia para área social​', 'Cisterna con equipo completo​']
            },
            {
                titulo: 'Segundo Nivel:',
                elementos: ['Sala familiar', 'Dormitorio principal con vestidor y baño completo ​', 'Dormitorio tres y cuatro con baño completo', 'Bodega​', 'Área de Lavandería​', 'Dormitorio de Servicio']
            }
        ]
    },
    {
        subtitulo: '¡Vivienda completamente nueva!',
        titulo: 'Las Uvas',
        default: img.uvas[0],
        descripcion: 'Hermosa vivienda ubicada en zona exclusiva de Tegucigalpa, Residencial Las Uvas, circuito cerrado, finos acabados, construida en un terreno de 415.48 varas y 456.89 metros de construcción, la vivienda consta de:',
        precio: 'Precio: L. 9,000,000.00',
        items: img.uvas,
        detalle: [
            {
                titulo: 'Primer Nivel:',
                elementos: ['Garaje techado​', 'Patio delantero con jardín​', 'Sala y Sala de estar​', 'Estudio​, Alacena​, Comedor​ y Bodega​', 'Cocina amueblada con Isla​', 'Baño completo​', 'Patio trasero techado con fuente para área social​', 'Cisterna con equipo completo​']
            },
            {
                titulo: 'Segundo Nivel:',
                elementos: ['Terraza techada​, Sala Familiar y Área de lavandería techada', 'Dormitorio principal con vestidos, baño completo y bañera con hidromasaje​', 'Dormitorio secundario con baño completo​', 'Dormitorio tres y cuatro con baño compartido​​', 'Dormitorio de servicio con baño completo​']
            }
        ]
    }
]

export default {links}