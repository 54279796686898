import Uvas01 from './LasUvas1.webp';
import Uvas02 from './LasUvas2.webp';
import Uvas03 from './LasUvas3.webp';
import Uvas05 from './LasUvas5.webp';
import Uvas06 from './LasUvas6.webp';
import Uvas08 from './LasUvas8.webp';
import Uvas09 from './LasUvas9.webp';
import Uvas10 from './LasUvas10.webp';
import Uvas11 from './LasUvas11.webp';
import Uvas12 from './LasUvas12.webp';
import Uvas13 from './LasUvas13.webp';
import Uvas14 from './LasUvas14.webp';
import Uvas15 from './LasUvas15.webp';
import Uvas16 from './LasUvas16.webp';
import Uvas17 from './LasUvas17.webp';
import Uvas18 from './LasUvas18.webp';
import Uvas19 from './LasUvas19.webp';
import Uvas20 from './LasUvas20.webp';

const uvas = [
    Uvas01,
    Uvas02,
    Uvas03,
    Uvas05,
    Uvas06,
    Uvas08,
    Uvas09,
    Uvas10,
    Uvas11,
    Uvas12,
    Uvas13,
    Uvas14,
    Uvas15,
    Uvas16,
    Uvas17,
    Uvas18,
    Uvas19,
    Uvas20,
];

import RobleOeste01 from './RobleOeste1.webp';
import RobleOeste03 from './RobleOeste3.webp';
import RobleOeste04 from './RobleOeste4.webp';
import RobleOeste06 from './RobleOeste6.webp';
import RobleOeste07 from './RobleOeste7.webp';
import RobleOeste08 from './RobleOeste8.webp';
import RobleOeste09 from './RobleOeste9.webp';
import RobleOeste10 from './RobleOeste10.webp';
import RobleOeste11 from './RobleOeste11.webp';
import RobleOeste12 from './RobleOeste12.webp';
import RobleOeste13 from './RobleOeste13.webp';
import RobleOeste14 from './RobleOeste14.webp';
import RobleOeste15 from './RobleOeste15.webp';
import RobleOeste16 from './RobleOeste16.webp';
import RobleOeste17 from './RobleOeste17.webp';
import RobleOeste18 from './RobleOeste18.webp';
import RobleOeste19 from './RobleOeste19.webp';

const roble_oeste = [
    RobleOeste01,
    RobleOeste03,
    RobleOeste04,
    RobleOeste06,
    RobleOeste07,
    RobleOeste08,
    RobleOeste09,
    RobleOeste10,
    RobleOeste11,
    RobleOeste12,
    RobleOeste13,
    RobleOeste14,
    RobleOeste15,
    RobleOeste16,
    RobleOeste17,
    RobleOeste18,
    RobleOeste19,
];

export default { uvas, roble_oeste }