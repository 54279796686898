import { createRoot } from 'react-dom/client';
import './css/index.css';
import './css/nav-toggle.css';
import Layout from './components/Layout';
import Home from './pages/Home';
import 'animate.css';
import { Toaster } from 'sonner';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';

const container = document.querySelector('#root') as Element;
const root = createRoot(container);

root.render(
    <Layout >
        <Toaster className='notificacion' closeButton={true} />
        <Home />
    </Layout>
);
