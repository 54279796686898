import casa_en_mano from '../assets/img/Casa_en_mano.webp';
import familia from '../assets/img/Familia.webp';
import serviciosIcono from '../assets/img/servicios.svg';
import visionIcono from '../assets/img/vision.svg';
import misionIcono from '../assets/img/mision.svg';
import { useRef, useState } from 'react';
import { toast } from 'sonner';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PhoneInput from 'react-phone-number-input';
import { Form, Formik, Field, FormikHelpers } from 'formik';
import { contactSchema } from '../utils/formValidation';
import { sendForm } from "emailjs-com";
import { secrets } from '../utils/secrets';
import Catalogo from '../components/Catalogo';

interface IContact {
    Nombre: string;
    Correo: string;
    Descripcion: string;
    Telefono: string;
    Asunto: string;
}

const Home = () => {
    const animate = useRef(null);
    const formRef = useRef(null);
    const [TelefonoInput, setTelefonoInput] = useState<any>();
    const handleSubmit = async (values: IContact, actions: FormikHelpers<IContact>) => {
        actions.setFieldValue('Telefono', TelefonoInput);
        actions.validateField('Telefono');
        sendForm(secrets.emailJsService, secrets.emailJsTemplate, formRef?.current || "" as HTMLFormElement | string, secrets.emailJsKey)
            .then(() => {
                scrollTo(0, 0);
                toast.message(
                    `Mensaje enviado!`,
                    {
                        description: `Hemos recibido tu correo con el asunto "${values.Asunto}". Gracias por tu mensaje, lo revisaremos y te responderemos en breve.`,
                        position: "top-center",
                        duration: 9000,
                    }
                );
                actions.resetForm();
                setTelefonoInput("");
            }).catch((_error) => {
                toast.error("Ocurrio un error!")
            });
    }

    return (
        <main>
            <div ref={animate} className={`slogan`}>
                <p>Tu Inversión de</p>
                <p>Vivienda u Oficina</p>
                <p>Están Seguras</p>
            </div>
            <section className="container mision" id="mision">

                <LazyLoadImage
                    className="container-img modal-img"
                    src={casa_en_mano}
                    alt="Casa en la mano"
                    loading="lazy"
                />
                <div >
                    <div className="info">
                        <img src={misionIcono} alt="Icono Misión" />
                        <h2>Misión</h2>
                    </div>
                    <p className='descripcion'>
                        Facilitar el sueño de cada familia de encontrar su hogar ideal mediante un servicio personalizado, transparentes y transacciones un compromiso inquebrantable con la satisfacción del cliente.
                    </p>
                </div>
            </section>
            <section id="vision" className="container vision">
                <LazyLoadImage
                    className="container-img modal-img"
                    src={familia}
                    alt="Casa en la mano"
                    loading='lazy'
                />
                <div>
                    <div className="info">
                        <img src={visionIcono} alt="Icono Visión" />
                        <h2 >Visión</h2>
                    </div>
                    <p className='descripcion'>
                        Convertirnos en la principal opción de conﬁanza en el mercado inmobiliario, reconocidos por nuestra integridad, innovación en soluciones residenciales y por superar las expectativas de nuestros clientes en cada paso del proceso de adquisición de un hogar.
                    </p>
                </div>
            </section>
            <section id="servicios" className="container servicios">
                <div className="info">
                    <img src={serviciosIcono} alt="Icono Servicios" />
                    <h2>Servicios</h2>
                </div>
                <ul>
                    <li>Compra-venta</li>
                    <li>Renta de viviendas</li>
                    <li>Terrenos</li>
                    <li>Edificios</li>
                    <li>Construcción de vivienda</li>
                    <li>Asesoría financiera</li>
                </ul>
            </section>
            <section id='catalogo' className="container catalogo">
                <Catalogo />
            </section>
            <section id='contactanos' className="container">
                <Formik
                    initialValues={{
                        Nombre: '',
                        Correo: '',
                        Asunto: '',
                        Telefono: '',
                        Descripcion: '',
                    }}
                    validationSchema={contactSchema}
                    onSubmit={handleSubmit}
                >
                    {({ handleChange, handleBlur, values, errors, touched, isSubmitting }) => (
                        <Form
                            ref={formRef}
                            method="POST"
                            name='contact'                        >
                            <label htmlFor="Nombre">
                                <Field
                                    required
                                    type="text"
                                    id="Nombre"
                                    name="Nombre"
                                    placeholder="Nombre"
                                    value={values?.Nombre}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors?.Nombre && touched?.Nombre && <p className={`error animate__animated ${errors.Nombre && 'animate__fadeIn'}`}>{errors?.Nombre}</p>}
                            </label>
                            <label htmlFor="Correo">
                                <Field
                                    required
                                    type="email"
                                    id="Correo"
                                    name="Correo"
                                    placeholder="Correo"
                                    value={values?.Correo}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors?.Correo && touched?.Correo && <p className={`error animate__animated ${errors.Correo && 'animate__fadeIn'}`}>{errors?.Correo}</p>}
                            </label>
                            <label htmlFor="Asunto">
                                <Field
                                    type="text"
                                    id="Asunto"
                                    name="Asunto"
                                    value={values?.Asunto}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Asunto"
                                />
                                {errors?.Asunto && touched?.Asunto && <p className={`error animate__animated ${errors.Asunto && 'animate__fadeIn'}`}>{errors?.Asunto}</p>}
                            </label>
                            <label htmlFor="Telefono">
                                <PhoneInput
                                    value={TelefonoInput}
                                    onChange={setTelefonoInput}
                                    international
                                    defaultCountry='HN'
                                    id="Telefono"
                                    name="Telefono"
                                    placeholder="Teléfono (Opcional)"
                                    required
                                />
                                {errors?.Telefono && touched?.Telefono && <p className={`error animate__animated ${errors.Telefono && 'animate__fadeIn'}`}>{errors?.Telefono}</p>}
                            </label>
                            <label className="big" htmlFor="Descripcion">
                                <textarea
                                    required
                                    value={values?.Descripcion}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="Descripcion"
                                    name="Descripcion"
                                    placeholder="Descripción"
                                />
                                {errors?.Descripcion && touched?.Descripcion && <p className={`error animate__animated ${errors.Descripcion && 'animate__fadeIn'}`}>{errors?.Descripcion}</p>}
                            </label>
                            <button className={`button`} disabled={isSubmitting} type="submit">Enviar</button>
                        </Form>
                    )}</Formik>
            </section>
        </main>
    )
}

export default Home;