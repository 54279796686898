const Footer = () => {
    return (
        <footer>
            <p className="phrase">
                “Dale a cada día la oportunidad de ser tu mejor día”
            </p>
        </footer>
    )
}

export default Footer;