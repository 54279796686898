//Iconos
import facebook from './iconos/facebook.webp';
import instagram from './iconos/instagram.webp';
import whatsapp from './iconos/whatsapp.webp';
import logo_blanco from './iconos/logo.svg';
import logo_azul from './iconos/logo-azul.svg';

const iconos = {
    logo_blanco,
    logo_azul,
    menu:[
        {
            link: 'https://www.facebook.com/profile.php?id=61565372814194',
            name:"Facebook Link Azul",
            img:facebook,
        },
        {
            link: 'https://www.instagram.com/inversiones504bienesraices?igsh=YWdyMm05ZWR1NTB6',
            name:"Instagram Link Blanco",
            img:instagram,
        },
        {
            link: 'https://wa.link/rkkm67',
            name:"Facebook Link Blanco",
            img:whatsapp,
        },
    ],
};

export default { iconos  }